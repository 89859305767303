<template>
    <div style="padding:10px;" >
        <!-- 功能区域 -->
        <div style="margin: 10px 0">
            <el-button type="primary" @click="add" >新增</el-button>
<!--            <el-popconfirm title="确定删除吗？" @confirm="deleteBatch">
                <template #reference>
                    <el-button type="danger" >批量删除</el-button>
                </template>
            </el-popconfirm>-->
        </div>

        <!-- 搜索区域 -->
        <div style="margin: 10px 0">
            <el-input v-model="search" placeholder="请输入" style="width: 20%" clearable/>
            <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
        </div>

        <!-- 表格 -->
        <el-table :data="tableData" stripe border style="width: 100%" >
            <el-table-column prop="id" label="ID" sortable />
            <el-table-column prop="name" label="物流单号" />
            <el-table-column prop="createTime" label="操作时间" />
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)" >编辑</el-button>
                    <el-popconfirm title="确认删除吗?" @confirm="handleDelete(scope.row.id)" >
                        <template #reference>
                            <el-button size="mini" type="danger">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div style="margin: 10px 0">
            <el-pagination
                    v-model:currentPage="currentPage"
                    :page-sizes="[5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>

            <!-- 新增 弹窗提示 -->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form :model="form" label-width="120px" :rules="rules">
                    <el-form-item label="物流单号" prop="name">
                        <el-input v-model="form.name" style="width:80%"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                    </span>
                </template>
            </el-dialog>

            <el-dialog title="详情" v-model="vis" width="50%">
              <el-card>

              </el-card>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import request from "../utils/request";
    export default {
        name: 'Book',
        components: {

        },
        data() {
            return {
                form: {},
                dialogVisible: false,
                search: '',
                currentPage:1,
                pageSize:10,
                total:0,
                tableData: [],
                user:{},
                vis:false,
                ids:[],
                rules: {
                  name: [{required: true, message: '请输入单号', trigger: 'blur',}],
                },
              }
        },
        created() {
            let userStr = sessionStorage.getItem("user") || "{}"
            this.user = JSON.parse(userStr)
            //请求服务端 确认当前登录用户的合法信息
            request.get("/user/" + this.user.id).then(res => {
                if (res.code === '0') {
                    this.user = res.data
                }
            })

            this.load()
        },
        methods: {
            load(){
              request.get("/book/list",{
                    params:{
                        pageNum:this.currentPage,
                        pageSize:this.pageSize,
                        search:this.search
                    }
                }).then(res => {
                    console.log(res)
                    this.tableData = res.data.records
                    this.total = res.data.total
                })
            },
            filesUploadSuccess(res){
                this.form.cover = res.data
            },
            add(){
                this.dialogVisible = true
                this.form={}
            },
            save(){
                if (!this.form.name) {
                  this.$message({
                    type: "error",
                    message: '请输入物流单号！'
                  })
                  return;
                }

                if(this.form.id){ //判断是否拥有用户ID 有则更新
                  request.post("/book/update",this.form).then(res => {
                        console.log(res)
                        if(res.code == '0'){
                            this.$message({
                                type: "success",
                                message: "更新成功"
                            })
                        }else{
                            this.$message({
                                type: "error",
                                message: res.msg
                            })
                        }
                        this.load() //刷新表格数据
                        this.dialogVisible = false //关闭弹窗
                    })
                }else{ //新增
                  request.post("/book/save",this.form).then(res => {
                        console.log(res)
                        if(res.code === '0'){
                            this.$message({
                                type: "success",
                                message: "新增成功"
                            })
                        }else{
                            this.$message({
                                type: "error",
                                message: res.msg
                            })
                        }
                        this.load() //刷新表格数据
                        this.dialogVisible = false //关闭弹窗
                    })
                }
            },
            details(row){
              this.detail = row
              this.vis = true
            },
            handleEdit(row){
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisible = true
            },
            handleDelete(id){
                console.log(id)
                request.delete("/book/"+id).then(res => {
                    if(res.code === '0'){
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        })
                    }else{
                        this.$message({
                            type: "error",
                            message: res.msg
                        })
                    }
                    this.load() //重新加载数据
                })
            },
            handleSizeChange(pageSize){ //改变当前每页的个数触发
                this.pageSize = pageSize
                this.load()
            },
            handleCurrentChange(pageNum){ //改变当前页码触发
                this.currentPage = pageNum
                this.load()
            },
            deleteBatch() {
                if (!this.ids.length) {
                    this.$message.warning("请选择数据！")
                    return
                }
                request.post("/book/deleteBatch", this.ids).then(res => {
                    if (res.code === '0') {
                        this.$message.success("批量删除成功")
                        this.load()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            handleSelectionChange(val) {
                this.ids = val.map(v => v.id)   // [{id,name}, {id,name}] => [id,id]
            },
        }
    }
</script>
